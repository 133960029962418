@tailwind base;
@tailwind components;
@tailwind utilities;

button,
a,
input[type="button"],
input[type="submit"],
[role="button"],
[tabindex],
.clickfix {
	touch-action: manipulation;
	user-select: none; /* Disable text selection */
	-webkit-user-drag: none; /* Disable dragging on iOS */
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0); /* Remove tap highlight color */
}

body {
	-webkit-touch-callout: none; /* Prevent callout, like copy, when long tapping */
}

#display-wrapper {
	background-image: linear-gradient(
			to bottom right,
			rgba(0, 0, 0, 0.8),
			transparent
		), /* Darker gradient overlay */ url("/img/leaf-wall.jpg"); /* Background image */
	background-size: cover;
	background-position: center;
}

.react-phone-number-input__country-select {
	border-radius: xxxs;
	height: calc(1.5em + 0.75rem + 2px);
}

.primary-link {
	text-decoration: none;
	color: var(--text-primary);
	&:hover {
		text-decoration: underline;
	}
}

.rich-text-editor-viewer .ProseMirror {
	padding: var(--mantine-spacing-xxs) !important;
	padding-bottom: var(--mantine-spacing-sm) !important;
	font-size: 0.875rem; /* optional, adjust as needed */
}



@layer base {
  :root {
		--background: 0 0% 100%;
		--foreground: 0 0% 3.9%;
		--card: 0 0% 100%;
		--card-foreground: 0 0% 3.9%;
		--popover: 0 0% 100%;
		--popover-foreground: 0 0% 3.9%;
		--primary: 0 0% 9%;
		--primary-foreground: 0 0% 98%;
		--secondary: 0 0% 96.1%;
		--secondary-foreground: 0 0% 9%;
		--muted: 0 0% 96.1%;
		--muted-foreground: 0 0% 45.1%;
		--accent: 0 0% 96.1%;
		--accent-foreground: 0 0% 9%;
		--destructive: 0 84.2% 60.2%;
		--destructive-foreground: 0 0% 98%;
		--border: 0 0% 89.8%;
		--input: 0 0% 89.8%;
		--ring: 0 0% 3.9%;
		--chart-1: 12 76% 61%;
		--chart-2: 173 58% 39%;
		--chart-3: 197 37% 24%;
		--chart-4: 43 74% 66%;
		--chart-5: 27 87% 67%;
		--radius: 0.5rem;
	}
  .dark {
		--background: 0 0% 3.9%;
		--foreground: 0 0% 98%;
		--card: 0 0% 3.9%;
		--card-foreground: 0 0% 98%;
		--popover: 0 0% 3.9%;
		--popover-foreground: 0 0% 98%;
		--primary: 0 0% 98%;
		--primary-foreground: 0 0% 9%;
		--secondary: 0 0% 14.9%;
		--secondary-foreground: 0 0% 98%;
		--muted: 0 0% 14.9%;
		--muted-foreground: 0 0% 63.9%;
		--accent: 0 0% 14.9%;
		--accent-foreground: 0 0% 98%;
		--destructive: 0 62.8% 30.6%;
		--destructive-foreground: 0 0% 98%;
		--border: 0 0% 14.9%;
		--input: 0 0% 14.9%;
		--ring: 0 0% 83.1%;
		--chart-1: 220 70% 50%;
		--chart-2: 160 60% 45%;
		--chart-3: 30 80% 55%;
		--chart-4: 280 65% 60%;
		--chart-5: 340 75% 55%;
	}
}



@layer base {
  * {
    @apply border-border;
	}
  body {
    @apply bg-background text-foreground;
	}
}
